









































import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import Search from '@/components/shared/Search.vue';
import EmptyDataset from '@/components/shared/EmptyDataset.vue';
import BaseResourceConfigMixin from '@/components/shared/ResourceConfigMixin';
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import {
  RegionPanel,
  REGIONS_PANELS_LAYOUT,
  useUiStore
} from '@/store/ui-store';
import { useLocationsStore } from '@/store/locations-store';
import { useRegionsStore } from '@/store/regions-store';
import { Region } from '@/model/region';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof BaseResourceConfigMixin>
>).extend({
  components: {
    LoadingIndicator,
    Search,
    EmptyDataset
  },
  mixins: [BaseResourceConfigMixin],
  data() {
    return {
      searchTerms: ''
    };
  },
  computed: {
    ...mapStores(useUiStore, useLocationsStore, useRegionsStore),
    isFetchingRegions(): boolean {
      return this.regionsStore.fetching;
    },
    regions(): Region[] {
      return this.regionsStore.entities;
    },
    currentRegion(): Region {
      return this.regionsStore.currentEntity;
    }
  },
  methods: {
    getRegionsFiltered(regions) {
      if (this.searchTerms) {
        return regions.filter(region => {
          const regex = new RegExp(region.name, 'gi');
          return regex.test(`${region.name}`);
        });
      }
      return regions;
    },
    editRegion(region) {
      this.uiStore.openPanel(REGIONS_PANELS_LAYOUT, RegionPanel.EDIT_REGION);
      this.regionsStore.setCurrentEntity(region);
    }
  }
});
