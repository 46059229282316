


































import ConfirmModal, {
  IConfirmModal
} from '@/components/shared/ConfirmModal.vue';
import BackButtonTitle from '@/components/shared/BackButtonTitle.vue';
import RegionForm from '@/components/regions/RegionForm.vue';
import BaseRegionMixin from '@/components/regions/BaseRegionMixin';
import ResourceConfigMixin from '@/components/shared/ResourceConfigMixin';
import Vue, { VueConstructor } from 'vue';
import { Collection } from '@/store/common/collections';
import { AlertType, RegionPanel, useUiStore } from '@/store/ui-store';
import { mapStores } from 'pinia';
import { useUsersStore } from '@/store/users-store';
import { useRegionsStore } from '@/store/regions-store';
import PlainButton from '@/components/shared/PlainButton.vue';

export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof BaseRegionMixin> &
    InstanceType<typeof ResourceConfigMixin>
>).extend({
  components: {
    ConfirmModal,
    BackButtonTitle,
    RegionForm,
    PlainButton
  },
  mixins: [BaseRegionMixin, ResourceConfigMixin],
  data() {
    return {
      deleteRegionModal: {} as IConfirmModal
    };
  },
  computed: {
    ...mapStores(useUiStore, useRegionsStore, useUsersStore)
  },
  mounted() {
    this.deleteRegionModal = (this.$refs
      .deleteRegionModal as unknown) as IConfirmModal;
  },
  methods: {
    closeCurrentPanel() {
      this.uiStore.closePanel(RegionPanel.EDIT_REGION);
      this.regionsStore.unsetCurrentEntity();
      this.regionsStore.unsetValidationErrors();
    },
    openDeleteRegionModal() {
      this.deleteRegionModal.openModal(
        this.$t('confirmModal.deleteRegion.message', {
          regionName: this.currentRegion.name
        }),
        {
          okTitle: this.$t('button.delete'),
          okVariant: 'danger'
        }
      );
    },
    async onConfirmRegionDeletion() {
      const regionSuccessfullyDeleted = await this.regionsStore.delete(
        this.currentRegion
      );
      this.deleteRegionModal.sendingData = false;
      if (regionSuccessfullyDeleted) {
        this.deleteRegionModal.closeModal();
        this.closeCurrentPanel();
        this.uiStore.alert(this.$t('toast.regionDeleted'), AlertType.SUCCESS);
      }
    },
    async editRegion({ region, locations }) {
      region = this.regionForApi({ region, locations });
      await this.regionsStore.edit(region, [Collection.LOCATIONS]);
    }
  }
});
