













































import RegionsList from '@/components/regions/RegionsList.vue';
import AddRegion from '@/components/regions/AddRegion.vue';
import EditRegion from '@/components/regions/EditRegion.vue';
import AddLocation from '@/components/locations/AddLocation.vue';
import Vue from 'vue';
import {
  RegionPanel,
  REGIONS_PANELS_LAYOUT,
  useUiStore
} from '@/store/ui-store';
import { mapStores } from 'pinia';
import { useUsersStore } from '@/store/users-store';
import { Region } from '@/model/region';
import { useRegionsStore } from '@/store/regions-store';
import PlainButton from '@/components/shared/PlainButton.vue';

export default Vue.extend({
  components: {
    RegionsList,
    AddRegion,
    EditRegion,
    AddLocation,
    PlainButton
  },
  data() {
    return {
      REGION_PANEL: RegionPanel
    };
  },
  computed: {
    ...mapStores(useUiStore, useRegionsStore, useUsersStore),
    regions(): Region[] {
      return this.regionsStore.entities;
    },
    currentRegion(): Region {
      return this.regionsStore.currentEntity;
    },
    openedPanels(): string[] {
      return this.uiStore.openedPanels;
    },
    currentPanelIndex(): number {
      return this.uiStore.currentPanelIndex;
    },
    vendorId(): number | null {
      return this.usersStore.loggedInVendorId;
    },
    isAdmin(): boolean {
      return this.usersStore.isAdmin;
    }
  },
  created() {
    this.regionsStore.findAll(this.usersStore.loggedInVendorId, true, false);
    this.uiStore.openPanel(REGIONS_PANELS_LAYOUT, RegionPanel.REGIONS_LIST);
    if (this.currentRegion?.id) {
      this.uiStore.openPanel(REGIONS_PANELS_LAYOUT, RegionPanel.EDIT_REGION);
    }
  },
  methods: {
    isPanelOpened(panel) {
      return this.uiStore.isPanelOpened(panel);
    },
    addRegion() {
      this.regionsStore.unsetCurrentEntity();
      this.uiStore.openPanel(REGIONS_PANELS_LAYOUT, RegionPanel.ADD_REGION);
    }
  }
});
